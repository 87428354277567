import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private router: Router) {}

    canActivate() {
        if (localStorage.getItem('access')) {
            return true;
        }
        if (this.router.url === '/signup') {
            this.router.navigate(['/signup']);
        } else if (this.router.url === '/confirm-email') {
            this.router.navigate(['/confirm-email']);
        } else {
            this.router.navigate(['/login']);
        }
        return false;

        /*  Original
        if (localStorage.getItem('isLoggedin') && localStorage.getItem('isLoggedin') != "false") {
            return true;
        }

        this.router.navigate(['/login']);
        return false;*/
    }
}
