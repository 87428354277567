export class Variables {
    static DEBUG = false;
    static DOMAIN = 'https://api.bio-hpcpre.ucam.edu';
    static SERV_API = Variables.DOMAIN + '/api/v1/';
    static API_MOLECULES =  Variables.SERV_API + '/molecules/';
    static API_SIMULATIONS =  Variables.SERV_API + '/simulations/';
    static API_DOWNLOAD_SIMULATIONS =  Variables.SERV_API + '/simulations/download/';
    static API_USER =  Variables.SERV_API + '/user/';
    static API_EMAIL = Variables.SERV_API + '/emails/';

    static API_AUTH_RESET_PASSWORD = Variables.SERV_API + '/rest-auth/password/reset/';

    static API_AUTH = Variables.SERV_API + '/token/';
    static API_AUTH_REFRESH = Variables.SERV_API + '/token_refresh/';
    static API_AUTH_REGISTRATION = Variables.DOMAIN + '/rest-auth/registration/';
    static API_AUTH_CONFIRM_EMAIL = Variables.DOMAIN + '/rest-auth/account-confirm-email/';
    static REFRESH_LST_SIMULATIONS = 15000; // 30000
    static IMG_DEFAUL_SIM = 'iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAHElEQVQI12P4//8/w38GIAXDIBKE0DHxgljNBAAO9TXL0Y4OHwAAAABJRU5ErkJggg==';
}

//  Enum para cuando se dibujan las molecuals
export enum ClusterType {
    CLUSTER = 0,
    MULTICLUSTER = 1,
}
