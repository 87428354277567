/*
  Author:     Jorge de la Peña
  email:      jorge.dlpg@gmail.com
  Version:    1.0
  Description: Servico para autentificarse, refrescar tokken o darse de alta
*/
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Variables } from './Variables';


@Injectable(  )
export class ServiceAuth {

    // http options used for making API calls
    private httpOptions: any;
    // the actual JWT tokenTranslateModule
    public token: string;
    public refresh: string;
    // the token expiration date
    public token_expires: Date;
    // the username of the logged in user
    public username: string;
    // error messages received from the login attempt
    
    public mistake_login: Boolean = false; //avisa si existe error en el login
    public detail_register: Boolean = false; //avisa si existe error en el login
    public errors: any [];
    public details: any [];
    constructor(private http: HttpClient) {
      this.httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
        })
      };
    }
    public confirm_email(id, method: any, own_class) {
      this.clear_extra_data();
      this.http.post(Variables.API_AUTH_CONFIRM_EMAIL, JSON.stringify({'key': id}) , this.httpOptions).subscribe(
        data => {
          // console.log(data)
          this.details.push (data['detail']);
          method(own_class);
          // console.log(data['detail'])
        },
        err => {
          console.log(err['error']);
          this.errors.push(err['error'].detail);
          method(own_class);
        }
      );
    }

    // Uses http.post() to get an auth token from djangorestframework-jwt endpoint
    public sign_up(user, method: any) {
      this.clear_extra_data();
      this.http.post(Variables.API_AUTH_REGISTRATION, JSON.stringify(user), this.httpOptions).subscribe(
        data => {
          method.clear_form();
          this.details.push (data['detail']);
          console.log(data);
          this.detail_register = true;
        },
        err => {
          this.mistake_login  = true;
          this.errors = [];
          if (err.error.email) { this.errors.push('Email: ' + err.error.email[0]); }
          if (err.error.username) { this.errors.push('Username: ' + err.error.username[0]); }
          if (err.error.password1) { this.errors.push('Password 1: ' + err.error.password1[0]); }
          if (err.error.password2) { this.errors.push('Password 2: ' + err.error.password2[0]); }
          if (err.error.non_field_errors) { this.errors.push('Password : ' + err.error.non_field_errors[0]); }
          if (this.errors.length == 0)
            if (err['message']) { this.errors.push(err['message']); }
        }
      );
    }
    // Uses http.post() to get an auth token from djangorestframework-jwt endpoint
    public login(user,  method: any, own_class) {
      this.clear_extra_data();
      if (user.username === "" || user.passowrd === "")
      {
        this.errors = ['Error: password or user empty'];
        this.mistake_login = true;
        method(own_class);
      }
      else {
        this.http.post(Variables.API_AUTH, JSON.stringify(user), this.httpOptions).subscribe(
          data => {
            localStorage.setItem('refresh', data['refresh']);
            this.updateData(data);
            method(own_class);
          },
          err => {
            console.log(err);
            this.mistake_login = true;
            if (err['error']['non_field_errors']) {
              this.errors = err['error']['non_field_errors'];
            } else {this.errors = [err['message']]; }
              method(own_class);
          }
        );
      }
    }
    public clear_extra_data() {
      this.mistake_login  = false;
      this.errors = [];
      this.details = [];
      this.detail_register = false;
    }

    // Refreshes the JWT token, to extend the time the user is logged in
    public refreshToken() {
      const refresh = localStorage.getItem('refresh');
      return this.http.post(Variables.API_AUTH_REFRESH, JSON.stringify({refresh: refresh}), this.httpOptions)
    }
    public getAccessToken() {
      // console.log(localStorage.getItem('access'));
      return localStorage.getItem('access');
    }
    public logout() {
      this.token = null;
      this.token_expires = null;
      this.username = null;
      this.mistake_login = false;
      // localStorage.removeItem('access');
      // localStorage.removeItem('refresh');
      localStorage.clear();
    }
    private updateData(data) {
      this.token = data['access'];
      this.errors = [];
      // decode the token to read the username and expiration timestamp
      const token_parts = this.token.split(/\./);
      const token_decoded = JSON.parse(window.atob(token_parts[1]));
      this.token_expires = new Date(token_decoded.exp * 1000);
      this.username = token_decoded.username;
      localStorage.setItem('access', this.token);
      this.http.get(Variables.API_USER,  this.httpOptions).subscribe(
        data => {
          localStorage.setItem('user_name', data['user_name']);
        }
        ,err => {
          console.log('Error getting user');
        }
      )
    }
    public is_authenticate() {
      if (localStorage.getItem('access')) {return true; }
      return false;
    }
  }
