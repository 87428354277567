import { Injectable } from '@angular/core';

import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
  } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
import { throwError } from 'rxjs';
// import 'rxjs/Observable';
// import 'rxjs/add/observable/throw';
// import  'rxjs/add/observable/throw'

import { filter,  take } from 'rxjs/operators';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { switchMap, catchError } from 'rxjs/operators';
import { ServiceAuth } from './Auth.service';
import { __core_private_testing_placeholder__ } from '@angular/core/testing';
// import { Variables }   from './Variables'
/*
    Añade en todas las cabecesras el token de acceso si existe
    #https://theinfogrid.com/tech/developers/angular/refreshing-authorization-tokens-angular-6/
    #
    # Util
    # https://josuevalrob.com/article/intercept-and-refresh-token-in-angular-for-drupal8
*/
@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    // constructor(private loginService: LoginService) {}
    private refreshTokenInProgress = false;
    // Refresh Token Subject tracks the current token, or is null if no token is currently
    // available (e.g. refresh pending).
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
        null
    );
    constructor(private loginService: ServiceAuth) {}

    intercept( request: HttpRequest<any>,  next: HttpHandler): Observable<HttpEvent<any>> {
        //if (Variables.DEBUG) console.log("_____________________________Interceptor_______________________________________________") 
        request = this.addAuthenticationToken(request)
        return next.handle(request).catch(error => {
            // if (Variables.DEBUG) console.log("jwinterceptioon ERROR : "+error.status)
            // We don't want to refresh token for some requests like login or refresh token itself
            // So we verify url and we throw an error if it's the case
            if ( request.url.includes('refreshtoken') || request.url.includes('login')) {
                // if (Variables.DEBUG) console.log('request.url.includes("refreshtoken") || request.url.includes("login")')
                // We do another check to see if refresh token failed
                // In this case we want to logout user and to redirect it to login page

                if (request.url.includes('refreshtoken')) {
                    this.loginService.logout();
                }

                return throwError(error);
            }

            // If error status is different than 401 we want to skip refresh token
            // So we check that and throw the error if it's the case
            else if (error.status !== 401) {
                // if (Variables.DEBUG) console.log("diferente a 401 "+error.status)
                return throwError(error);
            }

            else if (this.refreshTokenInProgress) {
                // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
                // – which means the new token is ready and we can retry the request again
                // console.log(this.loginService.getAccessToken())
                return  this.refreshTokenSubject.pipe( 
                    filter(result => result !== null) ,
                    take(1),
                    switchMap(
                        () =>
                        next.handle(this.addAuthenticationToken(request)
                    )));
            } else {
                this.refreshTokenInProgress = true;

                // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
                this.refreshTokenSubject.next(null);

                // Call auth.refreshAccessToken(this is an Observable that will be returned)
                return this.loginService.refreshToken().pipe(
                    switchMap((token: any) => {
                        // When the call to refreshToken completes we reset the refreshTokenInProgress to false
                        // for the next time the token needs to be refreshed
                        // console.log("refereshtoken")
                        this.refreshTokenInProgress = false;
                        this.refreshTokenSubject.next(token);
                        localStorage.setItem('access', token.access);
                        return next.handle(this.addAuthenticationToken(request));
                    })
                    ,catchError((err: any) => {
                        this.refreshTokenInProgress = false;
                        this.loginService.logout();
                        return throwError(error);
                    }));
            }
        });
    }

    addAuthenticationToken(request) {
        // Get access token from Local Storage
        const accessToken = this.loginService.getAccessToken();

        // If access token is null this means that user is not logged in
        // And we return the original request
        if (!accessToken) {
            return request;
        }

        // We clone the request, because the original request is immutable
        return request.clone({
            setHeaders: {
                Authorization: 'Bearer ' + this.loginService.getAccessToken()
            }
        });
    }
}
